import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
// import { isMobile } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';
import SEO from '../components/seo/seo';
import ExtendedContent from '../components/extendedContent';
import BackgroundChange from '../components/backgroundChange/BackgroundChange';
import getModulesWithBgChange from '../utils/getModulesWithBgChange';
import isObjectEmpty from '../utils/isObjectEmpty';
import { toggleIsIntroRendered } from '../state/app';

const StyledWrapper = styled.div`
	height: 100%;
	width: 100%;
`;

const SinglePage = ({ data: { singlePages, seoPojedynczePodstrony, allNewsData }, pageContext, isIntroRendered }) => {
	const activeSite = pageContext.site || '';
	const dispatch = useDispatch();
	useEffect(() => {
		// eslint-disable-next-line radix
		if (!isIntroRendered) {
			dispatch(toggleIsIntroRendered(true));
		}
	}, []);

	// const singlePageData = singlePages?.nodes?.[0] || [];
	//
	// const productCategoriesPagesData = productCategoriesPages?.nodes?.[0] || [];
	// const pageProductName = productPagesData?.title || '';
	// eslint-disable-next-line no-unused-vars
	// eslint-disable-next-line no-unused-vars
	// const checkPageName = singlePageData?.title || productPagesData.title || productCategoriesPagesData.title || '';
	// const pageName = checkPageName || '';
	// eslint-disable-next-line no-unused-vars
	// eslint-disable-next-line no-unused-vars
	const globals = pageContext?.globals?.data || [];
	const globalSeo = pageContext?.globals?.data?.globalSeo?.nodes?.[0] || [];
	const allModules = singlePages?.nodes?.[0]?.wybierzModul || '';
	const seoData = seoPojedynczePodstrony?.nodes?.[0] || [];
	const arrayForBgChanges = [];

	const addToArrayForBgChanges = (moduleToBgChange) => {
		if (!isObjectEmpty(moduleToBgChange)) {
			arrayForBgChanges.push(moduleToBgChange);
		}
	};

	if (allModules) {
		allModules.forEach((module) => {
			if (module.__typename === 'Craft_moduly_modulDaneKontaktowe_Entry') {
				const moduleDataArray = module?.modulDaneKontaktowe || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			} else if (module.__typename === 'Craft_moduly_modulNaglowekPodstrona2Linijki_Entry') {
				const moduleDataArray = module?.modulNaglowekPodstrona2Linijki || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			} else if (module.__typename === 'Craft_moduly_errorPageIlustracje_Entry') {
				const moduleDataArray = module?.errorPageIlustracje || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			}
		});
	}

	// arrayForBgChanges.push({ id: 'footer-el', bgColor: 'black' });

	return (
		<>
			<StyledWrapper>
				<SEO globalSeo={globalSeo} seoData={seoData} />
				{arrayForBgChanges.length > 0 && <BackgroundChange arrayForBgChanges={arrayForBgChanges} />}
				{/* <h1>{pageName || pageProductName}</h1> */}
				<ExtendedContent activeSite={activeSite} allNewsData={allNewsData} data={allModules} />
			</StyledWrapper>
		</>
	);
};

export default connect(
	(state) => ({
		isIntroRendered: state.app.isIntroRendered,
	}),
	null,
)(SinglePage);

export const query = graphql`
	query($remoteId: ID, $site: String, $firstOtherLangSite: String) {
		singlePages: allCraftPojedynczePodstronyPojedynczePodstronyEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
			nodes {
				slug
				title
				id: remoteId
				wybierzModul {
					__typename
					typeHandle
					remoteId
					...modulNapiszDoNas
					...modulSliderNaPodstronieProduktow
					...modulNaglowekFormularz
					...modulDaneKontaktowe
					...modulNaglowekPodstrona2Linijki
					...modulWyroznionaAktualnoc
					...modulWizytowkiNaPodstronieAktualnosci
					...errorPageIlustracje
				}
			}
		}
		globalCookies: allCraftCookiesGlobalSet(filter: { language: { eq: $site } }) {
			nodes {
				cookiesIkona {
					url
				}
				cookiesTekst
				cookiesNapisNaPrzycisku
			}
		}
		productsCategoriesPages: allCraftKategoriaProduktowKategoriaProduktowEntry(
			filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }
		) {
			nodes {
				slug
				title
				remoteId
			}
		}

		pojedynczeOtherLang: allCraftPojedynczePodstronyPojedynczePodstronyEntry(filter: { language: { eq: $firstOtherLangSite } }) {
			nodes {
				slug
				id: remoteId
			}
		}

		productPagesOtherLang: allCraftKategoriaProduktowKategoriaProduktowEntry(filter: { language: { eq: $firstOtherLangSite } }) {
			nodes {
				slug
				id: remoteId
			}
		}

		allNewsData: allCraftAktualnosciAktualnosciEntry(filter: { language: { eq: $site } }) {
			nodes {
				wizytowkaAktualnosciTytul
				wizytowkaAktualnosciOpis
				postDate
				slug
				uri
			}
		}

		seoPojedynczePodstrony: allCraftPojedynczePodstronyPojedynczePodstronyEntry(
			filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }
		) {
			nodes {
				...seoPojedynczePodstrony
			}
		}
	}
`;
